















































































































import { Component, Vue } from "vue-property-decorator";
import { RawLocation } from "vue-router";
import ComponentState from "@/models/Enum/ComponentState";
import { IUser } from "@/models/User";
interface NavigationItem {
  to?: RawLocation;
  label: string;
  icon?: string;
  children?: Array<NavigationItem>;
  active?: boolean;
  disabled?: boolean;
}

@Component({})
export default class MainShell extends Vue {
  private drawer: boolean | null = null;

  protected state = ComponentState.INITIAL;

  menuItems: Array<NavigationItem> = [
    {
      to: { name: "HomeRoute" },
      label: "Dashboard",
      icon: "fa-home",
    },
    {
      label: "Objecten",
      icon: "fa-clipboard-list",
      to: { name: "ObjectListView" },
      // children: [
      //   {
      //     to: { name: "Projects" },
      //     label: "Alle projecten",
      //   },
      //   {
      //     to: { name: "SupplyList" },
      //     label: "Goederenontvangst",
      //   },
      //   {
      //     to: { name: "DeliverablesListView" },
      //     label: "Opleverstaten",
      //   },
      // ],
    },
    // {
    // 	to: { name: 'Deals' },
    // 	label: 'Deals',
    // 	icon: 'fa-money-check-alt',
    // 	disabled: true,
    // },
  ];
  isGroup(item: NavigationItem): boolean {
    return Object.prototype.hasOwnProperty.call(item, "children");
  }

  private toggleDarkMode() {
    this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    localStorage.setItem("darkMode", String(this.$vuetify.theme.dark));
  }
  get theme(): string {
    return this.$vuetify.theme.dark ? "dark" : "light";
  }

  get authUser(): IUser {
    return this.$store.getters["auth/authUser"];
  }

  private handleLogoutButtonClick() {
    this.$store.dispatch("auth/logout");
  }
}
